<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <!-- Verify Captcha Stage -->
    <div v-if="isCaptchaEnabledForgotPassword" class="forgot-password" slot="component">
      <p class="forgot-password-title" v-if="!confirmation">
        {{ $t("Please verify Captcha to change password") }}
      </p>

      <br />

      <div class="login-form-resendLink" v-if="resendLink">
        <p class="login-form-resendLink-text">
          {{ resendLink }}
          <span @click="handleResendLink">{{ resendText }}</span>
        </p>
      </div>

      <br />

      <div class="form-group">
        <div v-if="formError" class="error-container">
          <p class="error">{{ formError }}</p>
        </div>
      </div>

      <br />

      <div v-if="captchaImage && appConfig.featureEnabled.isCaptchaEnabled">
        <label class="login-label">{{ $t("Please enter the numbers you see in the image") }}</label>
        <div
          v-if="captchaImage"
          :style="{
            backgroundImage: 'url(' + captchaImage + ')',
            'background-repeat': 'no-repeat',
            'background-color': 'white',
            width: '150px',
          }"
          class="captcha-image"
        ></div>
      </div>

      <div v-if="appConfig.featureEnabled.isCaptchaEnabled">
        <button @click="getAnotherCaptcha" class="button-link" type="button">
          {{ $t("Click to refresh if you can not read") }}
        </button>
      </div>

      <div class="form-group">
        <div class="form-control" v-if="appConfig.featureEnabled.isCaptchaEnabled">
          <div class="label-container">      
            <!-- <label class="login-label"> {{ $t("The result") }} </label>
            <span class="required">*</span> -->
          </div>
          <div class="input-container" :dir="[ localDisplayLang === 'ara' ? 'rtl' : 'ltr' ]">
            <input type="text" v-model="enteredCaptcha" :placeholder="`${this.$t('The result')}`" id="captcha" />
          </div>
        </div>
      </div>

      <button
        class="button-primary submit"
        :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
        type="button"
        @click="verifyCaptchaForgotPassword"
      >
        <span class="btn-txt-color">{{ $t("verify") }}</span>
      </button>
    </div>

    <!-- Forgot Password Recovery Stage -->
    <div v-else class="forgot-password" slot="component">
      <p class="forgot-password-title" v-if="!confirmation">
        {{ $t("Set new password") }}
      </p>
      <p class="forgot-password-subtitle">
        {{ forgotPasswordSubtitle }}
      </p>
      <!-- <p class="forgot-password-title" v-else>{{ $t("forgot password") }}</p> -->
      <form class="forgot-password-form" @submit.prevent="forgotPasswordConfirmation">
        <div class="form-group">
          <div v-if="formError" class="error-container">
            <p class="error">{{ formError }}</p>
          </div>
          <div class="form-control">
            <label for="email">{{ i18nEmailIdLabel }} / {{ i18nMobilenumberLabel }}</label>
            <input
              type="text"
              v-model="userInput"
              :placeholder="`${i18nEmailIdLabel} / ${i18nMobilenumberLabel}`"
              id="userinput"
              ref="userinput"
            />
          </div>
          <div class="form-control">
            <label for="password">OTP</label>
            <div id="pininputs" class="pin-inputs" dir="ltr">
              <input
                v-for="(item, index) in 6"
                :key="index"
                class="pin"
                type="text"
                v-model="userPin[item - 1]"
                @input="grabPin"
                maxlength="1"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="form-control">
            <label for="newPassword">{{ $t("enter new password") }}</label>
            <div class="input-container" dir="ltr">
              <input
                type="password"
                v-model="newPassword"
                autocomplete="new-password"
                :placeholder="`${enternewPasswordPlaceholder}`"
                id="newPassword"
              />
              <span class="eye">
                <img :src="eyeopen" @click="toggleEye($event)" alt />
              </span>
            </div>
          </div>
        </div>

        <button
          class="button-primary submit"
          :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
          type="submit"
        >
          <span class="btn-txt-color">{{ $t("submit") }}</span>
        </button>
      </form>
    </div>
  </Popup>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { eventBus } from "@/eventBus";
import { _providerId } from "@/provider-config.js";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    closePopup: {
      type: Function,
    },
    input: {
      type: String,
    },
    inputtype: {
      type: String,
    },
  },

  data() {
    return {
      i18nEmailIdLabel: "",
      i18nMobilenumberLabel: "",
      enternewPasswordPlaceholder: "",
      width: "32%",
      margin: "",
      radiusType: "full",
      loader: false,
      userInput: null,
      otp: null,
      newPassword: null,
      formError: null,
      confirmation: false,
      userPin: [],
      profilePin: "",
      eyeopen: require("@/assets/icons/eye.svg"),
      eyeClose: require("@/assets/icons/eye-close.svg"),
      providerUniqueId: _providerId,
      localDisplayLang: null,
      forgotPasswordSubtitle: "",

      captchaImage: null,
      enteredCaptcha: null,
      captchaReferenceId: null,
      resendLink: null,

      isCaptchaEnabledForgotPassword: true,
      resendText: this.$t("Resend Link"),
    };
  },

  computed: {
    ...mapGetters(["getRtl", "appConfig"]),
  },

  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

      if (this.inputtype === "Email") {
        this.forgotPasswordSubtitle = this.$t("Please enter the OTP Sent to your email");
      } else {
        this.forgotPasswordSubtitle = this.$t("Please enter the OTP Sent to your mobile number");
      }
    this.isCaptchaEnabledForgotPassword = this.appConfig.featureEnabled.isCaptchaEnabled;

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });
    //setting margin and width for userauth popup based on screen width.
    if (screen.width < 576) {
      this.margin = "35% auto";
      this.width = "85%";
    } else {
      this.margin = "10% auto";
      this.width = "32%";
    }
  },

  mounted() {
    this.i18nEmailIdLabel = this.$t("email id");
    this.i18nMobilenumberLabel = this.$t("mobilenumber");
    this.enternewPasswordPlaceholder = this.$t("enter new password");

    if (this.isCaptchaEnabledForgotPassword) {
      this.getCaptchaDetails();
    } else {
      setTimeout(() => {
        this.setupForm();
      }, 100);

      this.setupPin();
    }

    eventBus.$on("forgotPasswordConfirmation-response", (response) => {
      if (response.reason) {
        this.formError = response.reason;
      } else if (!response.reason) {
        console.log("forgot-password-success", response);
        this.triggerSnackbarEvent();
        this.triggerAuthPopup();
        this.closePopup();
      }
    });

    eventBus.$on("forgotpassword-response", (response) => {

      console.log("THE RESPONSE HAS COME --- > ", response);
      if (response.reason) {
        if (response.errorcode === 6110) {
          this.resendLink = response.reason;
        } else {
          this.formError = response.reason;
        }
      } else {
        eventBus.$emit("auth-close");
        this.triggerSnackbarEventNew();
        // this.triggerForgotPasswordEvent();
        this.isCaptchaEnabledForgotPassword = false;

        setTimeout(() => {
          this.setupForm();
        }, 100);

        this.setupPin();
      }
    });

  },

  methods: {
    ...mapActions(["getCaptcha"]),

    getCaptchaDetails() {
      this.getCaptcha().then((data) => {
        if (!data.reason) {
          this.captchaImage = data.payload;
          this.captchaReferenceId = data.referenceid;
        } else {
          this.formError = this.$t(data.reason);
        }
      });
    },

    triggerSnackbarEventNew() {
      let payload = {
        state: true,
        message:
          this.inputtype === "Email" ? "OTP has been sent to your mail id" : "OTP has been sent your mobile number",
        color: "#ff9600",
      };
      eventBus.$emit("snackBarMessage", payload);
    },

    verifyCaptchaForgotPassword() {
      if (!this.validateCaptcha()) {
        this.formError =this.$t("Please enter the Captcha");
        return;
      }

      let payload = {};

      if (this.inputtype === "Email") {
        payload.email = this.input;
      } else {
        payload.mobileno = this.input;
      }

      if (this.appConfig.featureEnabled.isCaptchaEnabled && this.validateCaptcha()) {
        payload.captcha = JSON.stringify({
          text: this.enteredCaptcha,
          referenceid: this.captchaReferenceId,
        });
      }

      eventBus.$emit("forgotPasswordRequest", payload);
    },

    getAnotherCaptcha() {
      this.getCaptchaDetails();
    },

    setupForm() {
      if (this.input) {
        this.userInput = this.input;
        this.$refs.userinput.disabled = true;
        let input = document.getElementById("userinput");
        input.style.backgroundColor = "#5C5C5D";
        input.style.opacity = "0.3";
      }
    },

    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
    },

    grabPin() {
      if (this.userPin.length > 3) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.profilePin = pin;
      }
    },

    forgotPasswordConfirmation() {
      if (!this.validateEmailOrMobile(this.userInput) || !this.validatePassword(this.newPassword)) return;
      let payload = {};

      if (this.inputtype === "Email") {
        payload.email = this.userInput;
      } else {
        payload.mobileno = this.userInput;
      }

      payload.otp = this.profilePin;
      payload.password = this.newPassword;

      console.log("THE CONTROL REACHED HERE -- > Forgot password submit!", payload)

      eventBus.$emit("forgotPasswordConfirmation", payload);
    },

    validateCaptcha() {
      console.log("THE CAPTCHA ENTERED -> ", this.enteredCaptcha);
      if (!this.enteredCaptcha) {
        this.formError = this.$t("Please Enter the given Captcha");
        return false;
      } else {
        return true;
      }
    },

    verifyOtp(pin) {
      if( !pin || pin.length < 6) {
        return true;
      } else {
        return false
      }
    },

    handleResendLink() {
      let payload = {
        state: true,
        input: this.inputtype === 'Email' ? "email" : 'mobile' ,
        formType: "verifyResendCaptcha", 
      };

      if (this.inputtype === "Email") {
        payload.email = this.input;
      } else {
        payload.mobileno = this.input;
      }

      eventBus.$emit("close-forgot-password-popup");

      eventBus.$emit("authPopup", payload);
    },

    triggerSnackbarEvent() {
      let payload = {
        state: true,
        message: "New Password has been updated.",
        color: "green",
      };
      eventBus.$emit("snackBarMessage", payload);
    },

    triggerAuthPopup() {
      let payload = {
        state: true,
        formType: "lookup",
      };
      eventBus.$emit("authPopup", payload);
    },
  },

  beforeDestroy() {
    eventBus.$off("forgotpassword-response");
    eventBus.$off("forgotPasswordConfirmation-response");
  },

  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
  },

  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
.forgot-password {
  padding: 60px 45px;
  &-title {
    color: #c1c1c1;
    font-family: $font-regular;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
  }

  &-subtitle {
    color: #c1c1c1;
    font-family: $font-regular;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 29px;
  }

  &-form {
    margin-top: 1rem;
  }
  .submit {
    margin-top: 1rem;
    width: 100%;
  }
}

.input-container {
  position: relative;
  .rtl-change {
    color: #ffffff;
    position: absolute;
    top: 32%;
    left: 3%;
  }

  .rtl-eye {
    position: absolute;
    top: 35%;
    left: 3%;
    opacity: 0.5;
    img {
      height: 18px;
      width: 18px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 576px) {
  .forgot-password {
    &-title {
      font-size: 16px;
    }
  }
}
</style>
